import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
// import { useEffect } from "react";
import loadable from '@loadable/component'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
// import Img from "gatsby-image"

// const Typewriter = loadable(() => import('typewriter-effect'));
const ContactCall = loadable(() => import('../components/ContactCall'));
const OfferRoll = loadable(() => import('../components/OfferRoll'));

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/oferta/${props.url}`} className='btnblog'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='btnblogdisabled'>
        {props.text}
      </span>
    )
  }
}


const OfferPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()+'/'
  const nextUrl = (index + 1).toString()+'/'
  // const { data } = props
  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.com/offer/'
   });

    return (
      <>
      <Helmet titleTemplate="%s">
        <meta name='image' content={'https://e-multicontent.pl/img/meta/oferta-meta.jpg'} />
        <meta property="og:description" content={'Zobacz w czym i jak możemy Ci pomóc.Zapraszamy do zapoznania się z naszymi usługami copywriterskimi! ✔️'}/>
        <meta property='og:title' content={'Oferta tworzenia treści | Agencja Copywriterska | e-multicontent.pl'} />
        <meta property='og:image' content={'https://e-multicontent.pl/img/meta/oferta-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.pl/oferta/'}/>
        <link rel="alternate" href="https://e-multicontent.com/offer/" hrefLang="en-gb" />
        <link rel="alternate" href="https://e-multicontent.com/offer/" hrefLang="en-us" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.pl" />
        <meta property="twitter:url" content="https://e-multicontent.pl/oferta/" />
        <meta name="twitter:title" content="Oferta tworzenia treści | Agencja Copywriterska | e-multicontent.pl" />
        <meta name="twitter:description" content="Zobacz w czym i jak możemy Ci pomóc.Zapraszamy do zapoznania się z naszymi usługami copywriterskimi! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />
        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.pl/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Oferta",
            "item": "https://e-multicontent.pl/oferta/"
          }]
        })}
        </script>

      </Helmet>
      {index === 1 ?
      <Helmet>
      <title>Oferta tworzenia treści - copywriting i tłumaczenia | e-multicontent.pl</title>
      <link rel="canonical" href="https://e-multicontent.pl/oferta/"  />
      <meta name="description" content={'Zobacz w czym i jak możemy Ci pomóc.Zapraszamy do zapoznania się z naszymi usługami copywriterskimi! ✔️'}/>
      </Helmet>
      :
      <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <title>{'Strona '+index+' - Oferta tworzenia treści - copywriting i tłumaczenia | e-multicontent.pl'}</title>
      <meta name="description" content={'Zobacz w czym i jak możemy Ci pomóc.Zapraszamy do zapoznania się z naszymi usługami copywriterskimi! ✔️ Strona ' + index}/>
      <link rel="canonical" href={'https://e-multicontent.pl/oferta/'+index+'/'}  />
      </Helmet>
      }
      <Layout>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Oferta</b></span>
      </nav>
      <div
        id="ofertatop"
        className="full-width-image-container"
        style={{
          backgroundPosition: `right bottom`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: 'contain',
          height:'550px',
          marginTop: '100px',
        }}
      >

        </div>



        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            lineHeight: '1',
            padding: '0.35em',
            borderRadius: '10px',
            //boxShadow: '5px 5px 20px rgb(23,19,43)',
            marginTop: '0%',
            position: 'absolute',
            left: '7%',
            top: '200px',
            zIndex:'1000',
            textAlign: 'left',
            backgroundColor:'rgba(255,255,255,0.75)'




          }}
        >
        Nasza
        <br />
         <strong style={{color:'#36b3d2'}}>oferta - copywriting</strong>
        </h1>
        <br />
        <h2
        className="mright"
        style={{
          color: 'rgb(23,19,43)',
          lineHeight: '1.2',
          padding: '0.35em',
          borderRadius: '10px',
          //boxShadow: '5px 5px 20px rgb(23,19,43)',
          marginTop: '0%',
          display: 'block',
          position: 'absolute',
          left: '8%',
          flexWrap: 'wrap',
          top: '450px',
          backgroundColor:'rgba(255,255,255,0.75)',
          textAlign: 'left',}} ><strong>Copywriting oferty</strong> dopasowane do Twoich potrzeb.
          <br />
          Zobacz w czym i jak możemy Ci pomóc.<br></br><br></br> Poznaj tajniki naszej <strong>oferty copywriting</strong>!
          </h2>

          <p className="ux" align="center" ><a className="btnoutline" href="#copywriting">⇩</a></p>

          <div id="copywriting" style={{marginTop:'-20px',backgroundColor:'rgba(255,255,255,0.7)'}}>

          <br></br>




          <div style={{marginTop:'0px',marginLeft:'5%',marginRight:'5%',backgroundColor:'rgba(255,255,255,0.7)'}}>
          <br></br>
          <p style={{maxWidth:'1000px',margin:'2%',padding:'0% 2% 0% 2%',borderLeft:'5px solid #36b3d2'}}>
            <strong>Od 2013 roku aktywnie wspieramy branżę e-commerce, przygotowując dla niej wielojęzyczny content</strong>. Mamy bogate doświadczenie w tworzeniu opisów produktowych i kategorii w języku polskim oraz w wielu językach obcych. Prowadzimy blogi firmowe, dostarczając odbiorcom ciekawe informacje i budując Twoją pozycję eksperta w branży. Znamy specyfikę platform sprzedażowych marketplace, dlatego przygotowanie kart produktowych oraz listingów dla Twojego sklepu internetowego nie ma przed nami tajemnic. Nasze treści są nie tylko skuteczne i przyjemne w odbiorze, ale również zoptymalizowane pod SEO, dzięki czemu Twoja witryna będzie lepiej pozycjonować się w wyszukiwarkach.
            <br /><br />
            Działamy równolegle w ponad 35 językach, dostarczając pakiet gotowych tekstów w tym samym czasie. Zajmujemy się copywritingiem w języku obcym, a także tłumaczeniami, lokalizacją i <Link to="/transkreacja-czyli-kreatywne-podejscie-do-tlumaczen/" className="dedlink">transkreacją</Link> contentu. Nasze treści są dostosowane do zagranicznego klienta, dzięki czemu łamiemy bariery językowe, ułatwiając Twojemu sklepowi internetowemu wejście na nowe rynki.
            <br /><br />
            Zobacz czym wyróznia sie nasza<strong style={{color:'#36b3d2'}}> copywriting oferta</strong>!
          </p>
            <div style={{paddingTop:'30px', width:'100%',textAlign:'center',marginBottom:'0px',margin:'auto'}}>
            <OfferRoll posts={group} />
            </div>
            <div className='buttons is-centered' style={{marginTop:'25px'}}>
              <PaginationLink test={first} url={previousUrl} text='<' />
              <PaginationLink test={last} url={nextUrl} text='>' />
            </div>
          </div>
          </div>
          <br></br>
          <br></br>
            {/*


          <div style={{textAlign:'center'}}><img loading="lazy" src="/img/io1.svg" width="150px" height="150px" alt="SEO, SEM, Copywriting, Tłumaczenia, E-commerce, Marketplace, Strony internetowe, Blogi"/></div>



          <div style={{marginRight:'5%',marginLeft:'5%',textAlign:'center'}}>
          <h2 style={{color:'#222222',fontSize:'1.7em'}}><b style={{color:'#36b3d2'}}>Nasza praca nie ma granic.</b> Mimo że serce naszej agencji mieści się we Wrocławiu, to nic nie stoi na przeszkodzie do współpracy z klientami z całego świata.</h2>
          <br></br>

          <h2 style={{textAlign:'center',fontSize:'1.5em'}}>Daj sobie szansę na <b style={{color:'#36b3d2'}}>wysoką sprzedaż!</b></h2>
          <br />
          <Link className="btn" style={{fontSize:'1.1em'}}
          to="/kontakt/">Skontaktuj się z nami &nbsp; &nbsp;
          <img loading="lazy" width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></Link>
          <br /><br />
          <br />


          </div>




        kontakt

          <div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>


          <p className="is-size-2" style={{fontWeight:'800'}}>
          <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString("<span style='color:#36b3d2'>Skontaktuj się</span> z nami:")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </p>
          <br />
          <br />


          <div className="columns" style={{margin:'auto'}}>

          <div className="column">
          <h3 style={{fontSize:'1em',textAlign:'center'}}>Chcesz się z nami skontaktować?<br /> Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
          <br />
          <FormMsg/>
          </div>

          <div className="column" style={{position:'relative',textAlign:'center'}}>
          <h3 style={{fontSize:'1em'}}>Chcesz otrzymać ofertę dla siebie?<br />Wypełnij brief.</h3>
          <br /><br />
          <a className="btn" style={{fontSize:'1.5em'}}
          href="https://docs.google.com/forms/d/e/1FAIpQLSdkSrYDjdS0qddoWvk7555qSST6N5Z4qpBcrVWoy7aZQ2_SqA/viewform"
          target="_blank" rel="noopener noreferrer">Brief e-multicontent &nbsp; &nbsp;
          <img width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></a>
          <br /><br />
          <br /><br />
          <Img fluid={data.file.childImageSharp.fluid}
          imgStyle={{objectFit:'contain'}}
          alt="Kontakt z e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
          style={{
            padding: "0",
            borderRadius: "10px",width:'100%',minHeight:'244px',
            maxWidth:'550px', border:'3px solid #f5f5f5',margin:'auto',marginBottom:'25px',
            backgroundColor: 'white', objectFit:'contain',objectPosition:'top center'}} />
          </div>

          </div>

          <div className="columns" style={{marginTop: '50px', paddingBottom: '70px',marginLeft:'3%',textAlign:'center' }}>

          <h3 style={{marginLeft:'3%', color:'#222222'}}>Chcesz wysłać maila?</h3>&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;<h4><a href="mailto:biuro@e-multicontent.pl" rel="noopener noreferrer" className="btn"  style={{marginTop:'-30px',marginBottom:'20px'}} target="_blank">biuro@e-multicontent.pl</a></h4>


          <h3 style={{marginLeft:'3%', color:'#222222'}}>Czy może wolisz porozmawiać?</h3>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h4><a href="tel:+48713074517" rel="noopener noreferrer" style={{marginTop:'-30px'}}  className="btn" target="_blank">+48 71 307 45 17</a></h4>
          </div>
          <div style={{width:'80%',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
          <Link className="btn" to="/"> Home </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/o-nas/" className="btn">O nas</Link>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/blog/"> Blog </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/portfolio/" className="btn"> Portfolio </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/kontakt/" className="btn"> Kontakt </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/kariera/" className="btn"> Kariera </Link> &nbsp;&nbsp;&nbsp;
          <a className="btn" href="#newsletter"> Newsletter </a>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/ebook/"> E-book </Link>&nbsp;&nbsp;&nbsp;
          </div>
          </div>




          <div
            className="full-width-image-container twenty lazy-background"
            style={{
              backgroundColor: '#36b3d2',
              backgroundPosition: `bottom left`,
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              height:'420px',
              marginBottom:'-50px',
              marginTop:'0px',
            }}
            loading="lazy"
          ></div>*/}

      <ContactCall />
      </Layout>
      </>
    )
  }


export default OfferPage


export const query = graphql`
  query FilesOf{
    file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        srcSetWebp
        src
        srcSet
      }
      }
    }
  }
`
